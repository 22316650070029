import $ from 'jquery';
import gsap from 'gsap';
import lottie from 'lottie-web';

gsap.config({
  nullTargetWarn: false,
});

export default class Loader {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$parentElement = this.$element.parents('body');
    // this.percent = `${this.element}_percent`;
    this.bg = `${this.element}_bg`;
    this.logo = `${this.element}_logo`;
    this.pageLoaded = false;
    this.nbImgLoad = 0;
    this.images = document.querySelectorAll('img');
    this.imagesLength = this.images.length;
  }

  init() {
    if (this.$element.length) {
      const imagesArray = Array.prototype.slice.call(this.images, 0);
      this.launchLoader();

      window.addEventListener('DOMContentLoaded', () => {
        // All images is loaded
        setTimeout(() => {
          this.pageLoaded = true;
          this.loadComplete();
        }, 2600);

        imagesArray.forEach((e) => {
          this.loadImages(e.src);
        });
      });
    }

    return false;
  }

  loadComplete() {
    if (
      this.nbImgLoad === this.imagesLength
      && this.pageLoaded === true
    ) {
      // const percent = document.querySelector(this.percent);
      const loaderBgHeight = $(this.bg).outerHeight();

      window.scrollTo(0, 0);

      gsap
        .timeline()
        .set(this.bg, {
          opacity: 1,
          bottom: 0,
          height: 0,
        })
        .add('start')
        .to(this.logo, {
        // .to(percentChars, {
          duration: 1,
          opacity: 0,
          // translateY: '-60vh',
          // stagger: 0.02,
        }, 'start')
        .to(this.bg, {
          duration: 1,
          height: '100vh',
        }, 'start+=0.2')
        .to(this.bg, {
          duration: 2.5,
          top: 0,
          height: loaderBgHeight,
          ease: 'elastic.out(0.5, 0.3)',
        }, 'start+=1.1')
        .to(this.element, { opacity: 0 }, 'start+=2.2');
      this.$parentElement.removeClass('el_body-isLoading');
    }
  }

  loadImages(srcImage) {
    const image = new Image();
    image.onload = () => {
      this.nbImgLoad += 1;
      this.loadComplete();
    };
    image.onerror = () => {
      this.nbImgLoad += 1;
      this.loadComplete();
    };
    image.src = srcImage;
  }

  launchLoader() {
    lottie.loadAnimation({
      container: document.querySelector(this.logo),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: $(this.logo).data('json'),
    });
  //   const $percent = $(this.percent);
  //   const tl = gsap.timeline({
  //     onUpdate: () => {
  //       const loadingProgress = Math.round(tl.progress() * 100);
  //       $percent.text(`${loadingProgress}%`);
  //     },
  //     onComplete: () => {
  //       const number = document.querySelector(this.percent).innerText;
  //       const splitNumber = number.split('');
  //       $percent.text('');
  //
  //       $.each(splitNumber, (i, elm) => {
  //         $percent.append(`<span>${elm}</span>`);
  //       });
  //     },
  //   });
  //   const tlO = gsap.timeline();
  //
  //   tlO.to($percent, { opacity: 1, duration: 0.2 });
  //   tl.to($percent, { duration: 2.3 });
  //   this.$parentElement.addClass('el_body-isLoading');
  }
}
